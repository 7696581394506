import React, { useLayoutEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { Modal } from "../Modal";
import { Prerequisites } from "../Prerequisites";
import { TimeChancesPrompt } from "../TimeChancesPrompt";
import { dateFormatting } from "../../../helpers/DateFormatting";
import { Icon } from "../../../atoms/components";
import { gql } from "@apollo/client";
import { useResourceViewScreenForModalsLazyQuery } from "../../../generated/graphql";
import { LoadingCentered } from "../../../molecules/components";
import { daysFromTodayWithTime } from "../../../helpers/DateFormatting";
import { ExpiredResource } from "../ExpiredResource";

export interface CourseBlockOpenModalsProps {
  courseUuid: string;
  scheduleUuid?: string;
  resourceUuid?: string;
  courseBlockUuid?: string;
  allowOpenModal: boolean;
  onModalClose?: () => void;
  expiryDate?: string | null;
}

export function CourseBlockOpenModals({
  courseUuid,
  scheduleUuid = "",
  allowOpenModal,
  onModalClose,
  resourceUuid,
  courseBlockUuid,
  expiryDate = "",
}: CourseBlockOpenModalsProps) {
  const [isTimerChanceModalOpen, setIsTimerChangeModalOpen] = useState(false);
  const [isPrereqModalOpen, setIsPrereqModalOpen] = useState<boolean>(false);
  const [isScheduledBlockModalOpen, setIsScheduledBlockModalOpen] =
    useState<boolean>(false);
  const [isExpiredResourceModalOpen, setIsExpiredResourceModalOpen] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const parentScreenName = searchParams.get("from_screen");

  const [
    resourceViewScreenForModalsQuery,
    { data, loading, error, refetch: resourceViewScreenForModalsQueryRefetch },
  ] = useResourceViewScreenForModalsLazyQuery();

  const resource = data?.resourceViewScreen?.resource;
  const currentBlock = data?.resourceViewScreen?.courseBlock;

  useLayoutEffect(() => {
    if (resourceUuid && courseBlockUuid) {
      resourceViewScreenForModalsQuery({
        variables: {
          resourceDetailsInput: {
            resourceUuid: resourceUuid || "",
            courseBlockUuid: courseBlockUuid || "",
            scheduleUuid: scheduleUuid || "",
          },
        },
      });
    }
  }, [resourceUuid, courseBlockUuid, allowOpenModal]);

  // If not accessible
  //// If open date is set later than current date
  ////// Show scheduled block modal
  //// Else
  ////// Show prereq modal
  //////// If assessment or max submissions reached
  ////////// Show assessment modal
  //////// Else
  ////////// Show prereq modal

  // Else If accessible
  //// If not assessment
  ////// Proceed to resource view screen
  //// If assessment
  ////// Show assessment modal

  useLayoutEffect(() => {
    setIsPrereqModalOpen(false);
    setIsTimerChangeModalOpen(false);
    setIsScheduledBlockModalOpen(false);
    setIsExpiredResourceModalOpen(false);
    const currentDate = Date.now();
    if (!currentBlock) return;

    if (!currentBlock.isAccessible) {
      if (
        currentBlock.scheduleReferenceInfo?.openDate &&
        currentDate <
        new Date(currentBlock.scheduleReferenceInfo?.openDate).getTime()
      ) {
        setIsScheduledBlockModalOpen(true);
      } else if (
        !currentBlock.scheduleReferenceInfo?.openDate &&
        currentBlock.openDate &&
        currentDate < new Date(currentBlock.openDate).getTime()
      ) {
        setIsScheduledBlockModalOpen(true);
      } else {
        if (
          resource?.type === "assessment" &&
          currentBlock.maxSubmissionsReached
        ) {
          setIsTimerChangeModalOpen(true);
        } else {
          if (expiryDate && currentDate > new Date(expiryDate).getTime()) {
            setIsExpiredResourceModalOpen(true);
          } else {
            setIsPrereqModalOpen(true);
          }
        }
      }
    } else {
      if (resource?.type !== "assessment") {
        navigate(
          {
            pathname: "../view",
            search: `?course_block_uuid=${currentBlock.uuid}${resource?.uuid || resourceUuid
              ? `&resource_uuid=${resource?.uuid || resourceUuid
              }&course_uuid=${courseUuid}&course_schedule_uuid=${scheduleUuid}&from_screen=${parentScreenName}${parentScreenName === "search"
                ? `&search_value=${searchParams.get("search_value")}`
                : ""
              }${(parentScreenName === "directoryviewer" || parentScreenName === "directoryseemore")
                ? `&path=${searchParams.get("path")}&base_path=${searchParams.get("base_path")}`
                : ""
              }`
              : ""
              }`,
          },
          {
            state: {
              scrollYValue: window.scrollY,
            },
          }
        );
      } else {
        setIsTimerChangeModalOpen(true);
      }
    }
  }, [resource, currentBlock]);

  if (!data && loading) return <></>;

  return (
    <>
      <Modal isOpen={allowOpenModal && loading} className="relative w-96 h-96">
        <LoadingCentered></LoadingCentered>
      </Modal>

      <Modal
        isOpen={allowOpenModal && isExpiredResourceModalOpen && !!currentBlock}
        onRequestClose={() => {
          setIsExpiredResourceModalOpen(false);
          onModalClose?.();
        }}
      >
        <ExpiredResource expiryDate={expiryDate as string}></ExpiredResource>
      </Modal>

      <Modal
        isOpen={allowOpenModal && isPrereqModalOpen && !!currentBlock}
        onRequestClose={() => {
          setIsPrereqModalOpen(false);
          onModalClose?.();
        }}
      >
        <Prerequisites
          resourceUuid={resourceUuid}
          courseBlockUuid={courseBlockUuid}
          onBlockResourceClick={(result) => {
            resourceViewScreenForModalsQueryRefetch({
              resourceDetailsInput: {
                resourceUuid: resourceUuid || "",
                courseBlockUuid: courseBlockUuid || "",
                scheduleUuid: scheduleUuid || "",
              },
            });
          }}
        />
      </Modal>

      <Modal
        isOpen={allowOpenModal && isTimerChanceModalOpen}
        onRequestClose={() => {
          setIsTimerChangeModalOpen(false);

          onModalClose?.();
        }}
        className="w-9/12 sm:w-1/2"
      >
        <TimeChancesPrompt
          onCancel={() => {
            setIsTimerChangeModalOpen(false);
            onModalClose?.();
          }}
          resourceUuid={resource?.uuid || resourceUuid}
          blockUuid={currentBlock?.uuid || courseBlockUuid}
          courseUuid={courseUuid}
          scheduleUuid={scheduleUuid || ""}
        />
      </Modal>
      <Modal
        isOpen={allowOpenModal && isScheduledBlockModalOpen}
        onRequestClose={() => {
          setIsScheduledBlockModalOpen(false);
          onModalClose?.();
        }}
      >
        <div className="w-full mx-auto">
          <h1 className="flex justify-center mb-4 text-2xl font-bold">
            Unlock on Schedule
          </h1>

          <p className="pb-4 my-2 text-sm text-center">
            This resource will unlock at the designated time mentioned below
          </p>

          <div className={"md:flex justify-between"}>
            {/* Timer Part Here */}

            <div className="w-full">
              <div className="p-2 text-center rounded bg-info-100">
                <div className="flex justify-center w-full my-2 text-sm text-info-500">
                  <Icon name="schedule" />
                  &nbsp;
                  <div className="uppercase">Access on</div>
                </div>

                {currentBlock?.scheduleReferenceInfo?.openDate ? (
                  <div className="my-4 text-2xl font-bold text-info-600">
                    {dateFormatting(
                      new Date(currentBlock?.scheduleReferenceInfo?.openDate)
                    )}
                  </div>
                ) : currentBlock?.openDate ? (
                  <div className="my-4 text-2xl font-bold text-info-600">
                    {dateFormatting(new Date(currentBlock?.openDate))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
