import React, { useState } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import { useDirectoryViewScreenQuery } from '../../../generated/graphql';
import { DirectoryCard } from '../../../molecules/components/DirectoryCard';
import { parseColorToStyle } from '../../../helpers/ColorHelper';
import {
    CourseResource,
    ScreenModal,
    TimerChancesPromptModal,
} from "../../../molecules/components/CastBlocks";
import { LoadingCentered } from '../../../molecules/components';
import { EnrollmentModals } from '../../../organisms/components';
import { NavBarLayout } from '../../../templates/components';
import { DirectoryNavbar } from '../../../organisms/components/DirectoryNavbar/DirectoryNavbar';
import { ResourceTypeSwitch } from '../../../organisms/components/CatalogBlock';

function ensureTrailingSlash(path?: string): string {
    return path?.endsWith('/') ? path : `${path}/`;
}

const DirectoryViewer: React.FC = () => {
    const [searchParams] = useSearchParams();
    const path = searchParams.get('path');
    const basePath = searchParams.get('base_path');
    const parentScreenName = searchParams.get('from_screen');
 
    const { loading, error, data } = useDirectoryViewScreenQuery({
        variables: {
            path: `${ensureTrailingSlash(path || undefined)}`,
            resourcesPath2: `${ensureTrailingSlash(path || undefined)}`,
        },
        errorPolicy: "all",
        fetchPolicy: "cache-and-network",
    });

    const [courseCardModal, setCourseCardModal] = useState<boolean>(false);
      const [batchModal, setBatchModal] = useState<boolean>(false);
      const [courseResource, setCourseResource] = useState<CourseResource>();
      const [resourceUuid, setResourceUuid] = useState<string>("");
      const [timePromptModal, setTimePromptModal] = useState<boolean>(false);
      const screenModal: ScreenModal = {
        objectFunction: {
          setCourseCardModal: setCourseCardModal,
          setBatchModal: setBatchModal,
          setCourseResource: setCourseResource as React.Dispatch<
            React.SetStateAction<CourseResource>
          >,
          setResourceUuid: setResourceUuid,
        },
        state: courseCardModal,
      };
      const timerChancesModal: TimerChancesPromptModal = {
        objectFunction: {
          setTimePromptModal: setTimePromptModal,
          setResourceUuid: setResourceUuid,
        },
        state: timePromptModal,
      };

    const directories = data?.directory_view_screen?.directory
    const resources = data?.directory_view_screen?.resources

    const resourceIconStyle = parseColorToStyle({
        // foregroundColor: appearance?.cardHeader?.backgroundColor,
        foregroundColor: {
            alpha: 1,
            blue: 143,
            green: 43,
            red: 117,
        }
    });

    if (loading) {
        return (
            <div className="relative h-60">
                <LoadingCentered></LoadingCentered>
            </div>
        );
    }

    return (
            <NavBarLayout>
                <DirectoryNavbar />

                   {/* Modal Course Card Goes Here */}
                      {batchModal || courseCardModal || timePromptModal ? (
                        <EnrollmentModals
                          courseCardModal={courseCardModal}
                          setCourseCardModal={setCourseCardModal}
                          batchModal={batchModal}
                          setBatchModal={setBatchModal}
                          courseResource={courseResource}
                          screenModal={screenModal}
                          resourceUuid={resourceUuid}
                          setTimePromptModal={setTimePromptModal}
                          timePromptModal={timePromptModal}
                        ></EnrollmentModals>
                      ) : (
                        <></>
                      )}

                <div
                    className="w-full px-10 py-6 md:px-20 animation-fade-in"
                //   style={bgStyle}
                >
                    <div className={"flex justify-between text-2xl mb-4"}>
                        {/* <p className="overflow-hidden font-black text-gray-900 whitespace-nowrap overflow-ellipsis">
              {resourceCollection?.name}
            </p> */}
                    </div>

                    <ul className="flex flex-col flex-wrap items-center md:flex-row">

                        {directories?.map((directory, i: number) => {
                            return (

                                <Link
                                    key={directory?.path}
                                    to={`/directoryviewer?path=${directory?.path}&from_screen=${parentScreenName}&base_path=${basePath}`}
                                >
                                    <DirectoryCard
                                        path={directory?.path}
                                        resourceIconStyle={resourceIconStyle}
                                    />
                                </Link>

                            );
                        })}

                        {resources?.map((resource, i: number) => {
                            return (
                                <ResourceTypeSwitch
                                    key={resource?.uuid}
                                    resource={resource || {}}
                                    resourceIconStyle={resourceIconStyle}
                                    screenModal={screenModal}
                                    timerChancesPromptModal={timerChancesModal}
                                    parentScreenName={parentScreenName || ""}
                                    path={path || ""}
                                    basePath={basePath || ""}
                                />
                            );
                        })}
                    </ul>
                </div>
            </NavBarLayout>
    );
}

export default DirectoryViewer;