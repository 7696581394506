import React, { useEffect, useState } from "react";

import { Resource } from "../../../pages/components/CatalogList";
import { Modal } from "../Modal";
import { Button, Icon, Loading, PillLabel } from "../../../atoms/components";
import { dateFormattingSchedules } from "../../../helpers/DateFormatting";
import { Link, useSearchParams } from "react-router-dom";
import {
  CourseSchedule,
  useEnrolledSchedulesLazyQuery,
  useEnrolledSchedulesQuery,
  useGetCourseProgressLazyQuery,
  useGetCourseProgressQuery,
} from "../../../generated/graphql";

export interface ChooseBatchModalProps {
  resource?: Resource;
  courseUuid: string;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ChooseBatchModal({
  resource,
  courseUuid,
  isModalOpen,
  setIsModalOpen,
}: ChooseBatchModalProps) {
  const [runEnrolledSchedulesQuery, { called, loading, data }] =
    useEnrolledSchedulesLazyQuery({
      variables: {
        courseUuid: courseUuid,
      },
      fetchPolicy: "no-cache",
    });

  useEffect(() => {
    if (isModalOpen) {
      runEnrolledSchedulesQuery();
    }
  }, [isModalOpen]);

  const schedulesData = data?.courseViewScreen?.course?.enrolledSchedules || [];

  const [activeSchedules, setActiveSchedules] = useState<CourseSchedule[]>([]);
  function sortActiveSchedules(schedules: CourseSchedule[]) {
    const onGoingSchedules: CourseSchedule[] = [];
    const scheduledSchedules: CourseSchedule[] = [];
    const doneSchedules: CourseSchedule[] = [];
    for (let i = 0; i < schedulesData.length; i++) {
      if (schedulesData[i])
        switch (schedulesData[i]?.status) {
          case "ONGOING":
            onGoingSchedules.push(schedulesData[i] as CourseSchedule);
            break;
          case "SCHEDULED":
            scheduledSchedules.push(schedulesData[i] as CourseSchedule);
            break;
          default:
            doneSchedules.push(schedulesData[i] as CourseSchedule);
        }
    }

    setActiveSchedules((prev) => [
      ...onGoingSchedules,
      ...scheduledSchedules,
      ...doneSchedules,
    ]);
  }

  useEffect(() => {
    if (schedulesData) sortActiveSchedules(schedulesData as CourseSchedule[]);
  }, [data]);

  return (
    <Modal
      className={" max-h-[80vh] w-4/5 md:w-3/5 lg:w-2/5 flex flex-col  "}
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
    >
      <h2 className="w-full mb-3 text-2xl font-black text-center text-gray-700">
        Select Batch
      </h2>

      {loading ? (
        <div className="w-1/4 mx-auto">
          <Loading />
        </div>
      ) : (
        <div className="overflow-y-auto ">
          {activeSchedules.map((schedule, i: number) => (
            <BatchCard
              key={i}
              schedule={schedule}
              courseUuid={courseUuid}
              resourceUuid={resource?.uuid || ""}
            />
          ))}
        </div>
      )}
    </Modal>
  );
}

function BatchCard({
  schedule,
  courseUuid,
  resourceUuid,
}: {
  schedule: CourseSchedule | null;
  courseUuid: string;
  resourceUuid: string;
}) {
  const { data, loading, error } = useGetCourseProgressQuery({
    variables: {
      courseUuid: courseUuid,
      scheduleUuid: schedule?.uuid,
    },
  });
  const pathArray = window.location.pathname.split("/");
  const parentScreenName = pathArray.pop();
  const [searchParams] = useSearchParams();

  const getBatchLink = (scheduleStatus: string) => {
    if (scheduleStatus === "SCHEDULED" || loading) {
      return "#";
    }

    return parentScreenName === "certification"
      ? `courseactivity?course_uuid=${courseUuid}&course_schedule_uuid=${schedule?.uuid}`
      : `/view?course_schedule_uuid=${schedule?.uuid}&resource_uuid=${resourceUuid || ""
      }&from_screen=${parentScreenName}${parentScreenName === "search"
        ? `&search_value=${searchParams.get("search_value")}`
        : ""
      }&from_screen=${parentScreenName}${(parentScreenName === "directoryviewer" || parentScreenName === "directoryseemore")
        ? `&path=${searchParams.get("path")}&base_path=${searchParams.get("base_path")}`
        : ""
      }`;
  };

  return (
    <div
      className="flex flex-col items-center w-full p-4 my-2 space-y-2 text-xs text-gray-600 bg-gray-100 rounded-lg drop-shadow-sm"
      // to={`/view?course_schedule_uuid=${schedule.uuid}&resource_uuid=${resource.uuid}&from_screen=${parentScreenName}${
      //   parentScreenName === "search"
      //     ? `&search_value=${searchParams.get("search_value")}`
      //     : ""
      // }`}
      key={schedule?.uuid}
    >
      <div className="w-full space-y-2 text-sm text-left">
        <PillLabel
          status={
            schedule?.status === "ONGOING"
              ? "info"
              : schedule?.status === "SCHEDULED"
                ? "scheduled"
                : "done"
          }
          className=""
        >
          {schedule?.status}
        </PillLabel>
        <div className="flex">
          <Icon name="date_range" />
          <div className="ml-1 text-left">
            <h6>{schedule?.name}</h6>
            <h6 className="flex font-bold">
              {dateFormattingSchedules(schedule?.start as string) +
                (!!schedule?.end ? " - " : "") +
                dateFormattingSchedules(schedule?.end as string)}
            </h6>
          </div>
        </div>
      </div>

      <Link
        to={getBatchLink(schedule?.status || "")}
        className={`text-base border-2 border-primary-500 text-primary-500 px-5 ml-auto ${schedule?.status === "DONE" ? " hidden " : ""
          } ${schedule?.status === "SCHEDULED" || loading
            ? " pointer-events-none hover:bg-none opacity-40"
            : "hover:bg-primary-300 transition-colors duration-300 "
          } rounded-full`}
      >
        <span className="text-primary-600">
          {schedule?.status === "SCHEDULED"
            ? "available soon"
            : loading
              ? "checking progress..."
              : data?.courseViewScreen?.courseProgressValue || 0 > 0
                ? "continue progress"
                : "start progress"}
          <Icon
            name="arrow_forward"
            className={`ml-2 ${schedule?.status === "SCHEDULED" && "hidden"}`}
          />
        </span>
      </Link>
    </div>
  );
}
