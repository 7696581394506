import { useNavigate, useSearchParams } from "react-router-dom";
import { Icon } from "../../../atoms/components";

export interface DirectoryNavbarProps {
    directoryName?: string;
    onBack?: () => any;
    onClose?: () => any;
    className?: string;
}

export function DirectoryNavbar({
    onBack,
    onClose,
    className,
}: DirectoryNavbarProps) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const directoryPath = searchParams.get("path") || "";
    const basePath = searchParams.get("base_path") || "";
    const parentScreenName = searchParams.get('from_screen');
    const normalizedDirectoryPath = directoryPath.endsWith("/") ? directoryPath.slice(0, -1) : directoryPath;
    const normalizedBasePath = basePath.endsWith("/") ? basePath.slice(0, -1) : basePath;
    const pathArray = normalizedDirectoryPath.split("/");
    const baseArray = normalizedBasePath.split("/");
    const MAX_FOLDER_LENGTH = 10
    const shouldExpand = pathArray.join(" / ").length > 50;

    const handleBreadcrumbClick = (index: number) => {
        const newPath = pathArray.slice(0, index + 1).join("/");
        navigate(`/directoryviewer?path=${newPath}&from_screen=${parentScreenName}&base_path=${basePath}`);
    };

    const handleBack = () => {
        if (onBack) {
            onBack();
            return;
        }

        // Only if basePath is /

        if (basePath === "/") {
            if (parentScreenName === "directoryseemore" && baseArray.length + 1 === pathArray.length) {
                navigate(`/directoryviewer?path=${basePath}&from_screen=${parentScreenName}&base_path=${basePath}`);
                return;
            }
        }

        // If screen comes from directoryviewer, go to home immediately if one level deeper than basePath
        // Otherwise, if screen comes from directoryseemore, go to basePath first

        if (
            normalizedDirectoryPath === normalizedBasePath ||
            (parentScreenName === "directoryviewer" && pathArray.length === baseArray.length + 1)
        ) {
            navigate(`/screen/home`);
            return;
        }

        // Go up one level
        const newPath = pathArray.slice(0, -1).join("/");
        navigate(`/directoryviewer?path=${newPath}&from_screen=${parentScreenName}&base_path=${basePath}`);
    };

    return (
        <div className={`${shouldExpand ? "h-auto" : "h-[6dvh]"} box-border`}>
            <div className="box-border flex items-center w-full h-full px-6 py-2 shadow-sm place-content-around">
                <button
                    onClick={handleBack}
                >
                    <Icon name="arrow_back" className="text-2xl text-gray-500"></Icon>
                </button>

                <div className="flex-1 text-base font-bold px-4 leading-5 text-center text-primary-500">
                    {pathArray.map((dir, index) => {
                        const truncatedDir = dir.length > MAX_FOLDER_LENGTH ? dir.slice(0, MAX_FOLDER_LENGTH) + "..." : dir;

                        return (
                            <span
                                key={index}
                                className="cursor-pointer"
                                onClick={() => handleBreadcrumbClick(index)}
                                title={dir} // Show full name on hover
                            >
                                {truncatedDir}
                                {index < pathArray.length - 1 && " / "}
                            </span>
                        );
                    })}
                </div>

                <button
                    onClick={() => {
                        if (onClose) {
                            onClose();
                        }
                        navigate(`/screen/home`);
                    }}
                >
                    <Icon name="close" className="text-2xl text-gray-500"></Icon>
                </button>
            </div>

        </div>
    );
}