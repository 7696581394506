import React, { useLayoutEffect, useState } from "react";
import { Navbar } from "../../../organisms/components";
import { Icon, Loading, PillLabel } from "../../../atoms/components";
import {
  CourseSectionStatus as CourseSectionStatusOriginal,
  ScheduleDetails as ScheduleDetailsOriginal,
  CourseBlockStatus as CourseBlockStatusOriginal,
  StatisticsLearnerCourseStatus as StatisticsLearnerCourseStatusOriginal,
  useGetGradesQuery,
} from "../../../generated/graphql";
import { Course } from "../CourseViewer";
import { CamelCasedType } from "../../../helpers/components";
import logo from "../../../assets/castImage.svg";
import { LoadingCentered } from "../../../molecules/components";
import { snakeCaseToTitleCase, titleCase } from "../../../helpers/TitleCase";

type CourseSectionStatus = CamelCasedType<CourseSectionStatusOriginal>;
type ScheduleDetails = CamelCasedType<ScheduleDetailsOriginal>;
type CourseBlockStatus = CamelCasedType<CourseBlockStatusOriginal>;
type StatisticsLearnerCourseStatus =
  CamelCasedType<StatisticsLearnerCourseStatusOriginal>;

export interface GradesProps {
  prop?: string;
}

export function Grades({ prop = "default value" }: GradesProps) {
  const { loading, data, error } = useGetGradesQuery();
  const [searchString, setSearchString] = useState<string>("");
  const [filteredLearnerCourseStatuses, setFilteredLearnerCourseStatuses] =
    useState<StatisticsLearnerCourseStatus[]>([]);

  useLayoutEffect(() => {
    if (data) {
      setFilteredLearnerCourseStatuses(
        data?.coursesAndCertificationScreen?.learnerCourseStatuses?.filter(
          (status) => status?.courseDetails?.isEnrolled
        ) as StatisticsLearnerCourseStatus[]
      );
    }
  }, [data]);

  useLayoutEffect(() => {
    if (searchString !== "") handleSearchStringChange(searchString);
    else
      setFilteredLearnerCourseStatuses(
        data?.coursesAndCertificationScreen?.learnerCourseStatuses?.filter(
          (status) => status?.courseDetails?.isEnrolled
        ) as StatisticsLearnerCourseStatus[]
      );
  }, [searchString]);

  const handleSearchStringChange = (searchString: string) => {
    const regex = new RegExp(searchString, "i");
    setFilteredLearnerCourseStatuses(
      data?.coursesAndCertificationScreen?.learnerCourseStatuses
        ?.filter((status) => {
          if (status?.courseDetails?.title)
            return regex.test(status?.courseDetails?.title);
        })
        .filter(
          (status) => status?.courseDetails?.isEnrolled
        ) as StatisticsLearnerCourseStatus[]
    );
  };

  return (
    <div className="flex flex-col bg-slate-200 min-h-[100dvh] h-fit">
      <Navbar />

      <main className="flex-1 h-full px-4 py-6 space-y-5 transition-all duration-500 md:px-14 lg:px-18">
        <h1 className="text-xl font-bold">Grades</h1>

        <div className="flex w-full space-x-2" data-testid="search">
          <input
            type="text"
            placeholder="Seach keywords"
            className="w-2/3 rounded-md border-slate-400"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
          {/* <button
            onClick={() => {}}
            className="px-3 py-2 border rounded-md border-slate-400 text-slate-500"
          >
            <Icon name="filter_list" /> Filter
          </button> */}
        </div>

        {loading && (
          <div className="flex-1">
            <LoadingCentered />
          </div>
        )}

        {!loading && (
          <div data-testid="course-card-section" className="space-y-6">
            {filteredLearnerCourseStatuses?.map((courseStatus, courseIndex) => {
              return (
                <div
                  key={
                    (courseStatus?.courseDetails?.uuid as string) + courseIndex
                  }
                >
                  <CourseCard
                    course={courseStatus?.courseDetails || {}}
                    courseStatus={courseStatus.status || "not_started"}
                    courseSections={
                      courseStatus?.sectionStatuses as CourseSectionStatus[]
                    }
                    scheduleDetails={
                      courseStatus?.scheduleDetails as ScheduleDetails
                    }
                  />
                </div>
              );
            })}
          </div>
        )}
      </main>
    </div>
  );
}

export interface CourseCardProps {
  course: Course;
  courseStatus: string | undefined;
  courseSections: CourseSectionStatus[] | undefined;
  scheduleDetails: ScheduleDetails;
}

export function CourseCard({
  course,
  courseStatus,
  courseSections,
  scheduleDetails,
}: CourseCardProps) {
  const { title, coverUri } = course;
  const {
    name: scheduleName,
    status,
    start: scheduleStart,
    end: scheduleEnd,
  } = scheduleDetails;

  return (
    <div className="flex flex-col w-full p-4 space-y-8 bg-white rounded-lg drop-shadow-lg h-fit">
      <PillLabel status={courseStatus || "not_started"}>
        {snakeCaseToTitleCase(courseStatus || "Not Started")}
      </PillLabel>

      <div className="flex flex-col items-center space-y-2 md:flex-row md:space-y-0 md:space-x-4 md:items-start">
        <img
          src={coverUri || logo}
          alt="Course preview"
          className="object-cover w-24 h-24 rounded-lg"
        />
        <div className="flex flex-col items-center md:items-start">
          <Icon name="school" className="text-slate-500" />
          <p className="text-xl font-bold">{title}</p>
        </div>
      </div>

      <div
        data-testid="schedule-div"
        className="p-4 space-y-1 text-center rounded-lg md:text-left bg-info-100 text-info-600"
      >
        <p className="text-lg font-bold">Batch Schedule</p>
        <span className="flex space-x-2">
          <Icon name="date_range" className="text-info-600" />
          <div className="flex flex-col w-full text-left ">
            <p className="text-lg">{scheduleName}</p>

            {scheduleStart || scheduleEnd ? (
              <p className="text-lg font-bold">
                {scheduleStart} {scheduleStart && scheduleEnd ? "-" : ""}{" "}
                {scheduleEnd}
              </p>
            ) : (
              <p className="text-lg font-bold">No schedule</p>
            )}
          </div>
        </span>
      </div>

      {!courseSections?.some((section) =>
        section?.blockStatuses?.some(
          (block) => block?.blockDetails?.requiredForCertificate
        )
      ) && (
        <div
          className="flex flex-col items-center justify-center w-full p-8 rounded-lg bg-slate-100"
          data-testid="activity-div"
        >
          This course does not contain any graded resources
        </div>
      )}

      <div
        className="flex flex-col w-full space-y-4 lg:grid lg:grid-cols-2 lg:gap-4 lg:space-y-0"
        data-testid="activity-div"
      >
        {courseSections?.map((section, sectionIndex) => {
          return section?.blockStatuses?.map((block, blockIndex) => {
            if (block?.blockDetails?.requiredForCertificate)
              return (
                <ActivityCard
                  key={`${sectionIndex} ${blockIndex}`}
                  block={block}
                />
              );
            else return null;
          });
        })}
      </div>
    </div>
  );
}

export interface ActivityCardProps {
  block: CourseBlockStatus;
}

export function ActivityCard({ block }: ActivityCardProps) {
  const { blockDetails, scoreMax, scoreRaw, status } = block;
  return (
    <div className="flex flex-col w-full p-2 border rounded-lg border-slate-400 min-h-28 md:flex-row md:p-4">
      {/* <pre>{JSON.stringify(block, null, 4)}</pre> */}
      <div className="flex space-x-2 md:flex-col md:space-x-0 md:w-2/3 ">
        <Icon name="assignment" className="text-slate-700" />
        <p className="line-clamp-2">{blockDetails?.title}</p>
      </div>

      <div className="flex justify-between w-full mt-auto md:flex-1 md:my-auto ">
        <PillLabel status={status || "not_started"}>
          {snakeCaseToTitleCase(status || "not_started") || "Not Started"}
        </PillLabel>
        <div className="flex">
          {(status === "passed" || status === "failed") && (
            <p className="font-bold ">
              {scoreRaw}/{scoreMax}
            </p>
          )}
          {/* 
          <Icon name="info_outline" className="bg-white" /> */}
        </div>
      </div>
    </div>
  );
}
