import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { Image, Icon, Button } from "../../../atoms/components";
import { useFileUpload } from "../../../helpers/FileUploadService";
import { gql, useLazyQuery } from "@apollo/client";
import { Modal } from "../Modal";
import { useErrorHandler } from "react-error-boundary";
import { ResourceContentAssessmentV2 } from "../../../pages/components/AssessmentViewerV2";
import {
  useFileSubmissionV2Mutation,
  useResourceEndMutation,
} from "../../../generated/graphql";
import { InputTextArea } from "../../../atoms/components";
import { Toast } from "../../../molecules/components";
import { LoadingCentered } from "../../../molecules/components";

const ViewResourceFileSubmissionV2ExamItems = gql`
  query Items($examTimeslotUuid: String!, $resourceUuid: String!) {
    assessmentViewScreen: assessment_view_screen(
      exam_timeslot_uuid: $examTimeslotUuid
      resource_uuid: $resourceUuid
    ) {
      examTimeslot: exam_timeslot {
        outline {
          exam {
            sections {
              items {
                uuid
                type
              }
            }
          }
        }
      }
    }
  }
`;

// Created new mutation here to save. Cant really change the casing for variables na papasok sa mutations. ExamSubmissionInputV2 will remain snake cased. sad

const FileSubmissionV2 = gql`
  mutation FileSubmissionV2(
    $updatedSubmission: ExamSubmissionInputV2!
    $submissionUuid: String!
    $blockUuid: String
    $courseScheduleUuid: String
  ) {
    assessmentSubmitUpdateV2: assessment_submit_update_v2(
      updated_submission: $updatedSubmission
      submission_uuid: $submissionUuid
      block_uuid: $blockUuid
      course_schedule_uuid: $courseScheduleUuid
    ) {
      __typename
    }
  }
`;

export interface ViewResourceFileSubmissionV2Props {
  content?: ResourceContentAssessmentV2;
  scheduleReferenceId?: string;
  cookRecipes?: {
    cookAsCompletedInsideCourse: () => void;
    cookAttemptInsideCourse: () => void;
    cookAsCompletedOutsideCourse: () => void;
    cookAttemptOutsideCourse: () => void;
  };
}

export function ViewResourceFileSubmissionV2({
  content,
  scheduleReferenceId = "",
  cookRecipes,
}: ViewResourceFileSubmissionV2Props) {
  const [searchParams] = useSearchParams();
  const parentScreenName = searchParams.get("from_screen");
  const searchValue = searchParams.get("search_value");

  const sectionWithItemMe =
    content?.examDetails?.examItemSet?.outline?.exam?.sections?.map(
      (section) => {
        return section?.items?.find((item) => item?.type === "ME");
      }
    );

  const itemWithTypeMe = sectionWithItemMe?.find((item) => item?.type === "ME");

  const draftValue = itemWithTypeMe?.draftValue;
  const mediaUrl = itemWithTypeMe?.mediaUrl;
  const fileUploadQuestion = itemWithTypeMe?.text;

  const [fileName, setFileName] = useState<string>(
    draftValue?.shortInput || "Upload file here"
  );
  const [submissionStatus, setSubmissionStatus] =
    useState<string>("in-progress");
  const courseUuid = searchParams.get("course_uuid");
  const scheduleUuid = searchParams.get("course_schedule_uuid");
  // const resourceUuid = searchParams.get("resource_uuid");
  // const courseBlockUuid = searchParams.get("course_block_uuid");

  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const getToastStrings = () => {
    if (submissionStatus === "draft") {
      return {
        title: "Draft Saved",
        message:
          "Your file will still be here when you revisit this file upload resource",
      };
    }

    if (!submissionError) {
      return { title: "File Submitted Successfully!", message: "" };
    }

    return {
      title: "File Submission Failed",
      message: "",
    };
  };

  const [file, setFile] = useState<File>();
  const [notes, setNotes] = useState<string>(draftValue?.longInput || "");
  const {
    loading,
    data: fileData,
    error,
    fileUpload,
  } = useFileUpload({
    file: file,
  });
  const [isDoneMutation, setIsDoneMutation] = useState<boolean>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleClear = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const [submitFileV2, { loading: submissionLoading, error: submissionError }] =
    useFileSubmissionV2Mutation();
  // const [resourceEndMutation] = useResourceEndMutation({
  //   variables: {
  //     courseScheduleUuid: scheduleUuid,
  //     resourceUuid: resourceUuid || "",
  //     courseBlockUuid: courseBlockUuid || "",
  //   },
  // });
  const timeslotUuid =
    scheduleReferenceId || content?.examDetails?.examTimeslotUuid;

  useErrorHandler(error);

  // Might not these to find the resources for assessment.
  const blockUuid = searchParams.get("course_block_uuid");
  const isInCourse = searchParams.get("course_uuid");

  const [isSure, setIsSure] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [
    getItemUuid,
    { loading: itemUuidLoading, data: itemUuidData, error: itemUuidError },
  ] = useLazyQuery(ViewResourceFileSubmissionV2ExamItems, {
    variables: {
      examTimeslotUuid: timeslotUuid,
      resourceUuid: searchParams.get("resource_uuid"),
    },
    onCompleted: (data) => {
      let itemMe: { uuid: string; type: string } =
        data?.assessmentViewScreen.examTimeslot.outline.exam.sections
          .find((section: any) => {
            return section.items.find((item: any) => item.type === "ME");
          })
          ?.items.find((item: any) => item.type === "ME");

      submitFileV2({
        variables: {
          courseScheduleUuid: scheduleUuid || "",
          blockUuid: blockUuid || "",
          submissionUuid:
            content?.examDetails?.examItemSet?.submission?.uuid || "",
          updatedSubmission: {
            responses: [
              {
                media_url: fileData?.uri || draftValue?.mediaUrl,
                item: itemMe.uuid,
                short_input: file?.name || draftValue?.shortInput,
                long_input: notes,
              },
            ],
            status: submissionStatus,
          },
        },
        // onCompleted: () => {
        //   resourceEndMutation();
        // },
      }).then(({ data }) => {
        setIsDoneMutation(true);
        setToastOpen(true);

        if (cookRecipes && submissionStatus !== "draft") {
          isInCourse
            ? cookRecipes.cookAsCompletedInsideCourse()
            : cookRecipes.cookAsCompletedOutsideCourse();
        }
      });
    },
  });

  useErrorHandler(itemUuidError);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    var files = e.target.files;
    var filesArray = [].slice.call(files);
    filesArray.forEach((el: any) => {
      setFileName(el.name);

      setFile(el);
    });
  };

  useLayoutEffect(() => {
    fileUpload();
  }, [fileName]);

  return (
    <div className="flex flex-col items-center mx-4">
      {/* Modal Before Submitting the File */}

      {toastOpen ? (
        <Toast
          title={getToastStrings().title}
          message={getToastStrings().message}
          closeDelay={3000}
          toastStatus="success"
          onClose={() => setToastOpen(false)}
        ></Toast>
      ) : (
        <></>
      )}

      <Modal isOpen={isSure} onRequestClose={() => setIsSure(false)}>
        <div className="text-center w-80">
          <h1 className="mb-2 text-2xl font-black">Are you sure?</h1>
          <h2 className="mb-2">
            This action cannot be undone after{" "}
            <span className="font-bold">submitting your file</span>{" "}
          </h2>

          {/* Action Buttons Div */}
          <div className="flex justify-around">
            <Button
              onClick={() => {
                getItemUuid();
                setIsSure(false);
                setIsSubmitted(true);
              }}
            >
              Confirm
            </Button>
            <Button color="primaryOutline" onClick={() => setIsSure(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <h1 className="py-4 text-lg font-bold text-gray-700">
        {fileUploadQuestion || "File Question"}
      </h1>
      {mediaUrl ? (
        <Image src={mediaUrl || ""} alt="" className="h-64 mb-4"></Image>
      ) : (
        <div className="h-64 mb-4"></div>
      )}

      {/* FORM SUBMISSION */}
      <form
        className="w-full text-gray-500"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="px-8 py-6 mt-2 mb-4 bg-gray-100">
          <input
            type="file"
            id="actual-btn"
            ref={fileInputRef}
            hidden
            required
            onChange={(e) => {
              handleChange(e);
            }}
            disabled={isSubmitted}
          />
          <div>
            <label htmlFor="actual-btn" className="flex flex-col items-center">
              <Icon
                name="upload"
                className={fileName !== "Upload file here" ? "hidden" : ""}
              ></Icon>
              <div className="flex content-center">
                <Icon
                  name="attachment"
                  className={fileName !== "Upload file here" ? "" : "hidden"}
                ></Icon>
                <p className="ml-2 mr-4">{fileName}</p>
                <button
                  onClick={() => {
                    setFileName("Upload file here");
                    handleClear();
                  }}
                  disabled={isSubmitted}
                >
                  <Icon
                    name="close"
                    className={fileName !== "Upload file here" ? "" : "hidden"}
                  ></Icon>
                </button>
              </div>
            </label>
          </div>
        </div>

        <InputTextArea
          name="notes-for-instructor"
          label="Notes for instructor"
          id="notes-for-instructor"
          className="mb-4 h-28"
          placeholder="Add notes here regarding your submitted file"
          disabled={isSubmitted}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
          value={notes}
        />
        <div className="flex mx-auto mb-4 w-fit">
          <Button
            className={`mr-2 ${isSubmitted || loading ? "hidden" : ""}`}
            onClick={() => {
              setSubmissionStatus("draft");
              getItemUuid();
              setIsSure(false);
              setIsSubmitted(true);
            }}
            disabled={
              !loading && fileName !== "Upload file here" ? false : true
            }
          >
            Save As Draft
          </Button>

          {/* CHanges when file has been submitted */}
          {isSubmitted ? (
            !isDoneMutation ? (
              <LoadingCentered />
            ) : (
              <Link
                to={
                  courseUuid
                    ? `/courseviewer?course_uuid=${courseUuid}&course_schedule_uuid=${scheduleUuid}&from_screen=${parentScreenName}`
                    : searchValue
                      ? `/screen/search?search_value=${searchValue}`
                      : `/screen/${parentScreenName}
                    ${(parentScreenName === "directoryviewer" || parentScreenName === "directoryseemore") ? `&path=${searchParams.get("path")}&base_path=${searchParams.get("base_path")}` : ""
                      }`
                }
                hidden={!isDoneMutation}
              >
                <Button
                  className="w-full mx-4 text-sm text-gray-100 border border-primary-600"
                  color="primary"
                  disabled={submissionLoading}
                >
                  {courseUuid ? "Return to Course" : "Return to Screen"}
                </Button>
              </Link>
            )
          ) : (
            <Button
              className={` ${isSubmitted ? "hidden" : ""}`}
              type="submit"
              color="secondary"
              disabled={
                !loading && fileName !== "Upload file here" ? false : true
              }
              onClick={() => {
                setIsSure(true);
              }}
            >
              {loading
                ? "Loading"
                : fileName === "Upload file here"
                  ? "Select File"
                  : "Submit"}
            </Button>
          )}
        </div>
      </form>
    </div>
  );
}
